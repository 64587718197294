.headingCell{
    align-items: center;
    justify-content: center;
    border-right: 1px solid var(--outline);
}

.cellUrl{
    word-break: break-word;
}

.detailsTable table{
    border-collapse: collapse;
}

.businessData{
    list-style-position: inside;
}

.controlShow {
    color: var(--outline);
    cursor: pointer;
}
 
.controlShow:hover{
    text-decoration: underline;
}