.searchDiv{
    margin: 10px 10px 10px 10px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    display: flex;
}

.searchInput{
    height: 50px;
    max-width: 800px;
    width: 100%;
    border-radius: 10px;
    border: 1px solid var(--outline);
    padding: 20px;
    font-size: 16px;
    margin-left: 5px;
}

.searchIcon{
    color: var(--outline)
}

@media screen and (max-width: 600px) {


    .searchDiv svg{
        display: none;
    }

}