
.robotsBanner {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin-top: 0px;
}

.robotsTooltip {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    font-size: 14px;
    color: var(--outline);
    margin: 10px 2px;
    padding: 2px;
}

.robotsTooltip p {
    margin: 0px;
}
