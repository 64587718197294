
.uncollapsedConstraint{
    border-collapse: collapse;
    background-color: white;
    max-height:1000px;
    transition:max-height 0.35s ease;
    border-top: 1px solid var(--outline);
    overflow: hidden;
    position: relative;
    cursor: default;
}

.constraintParagraph{
    margin: 0px;
}

.rule {
    margin: 20px;
    font-size: 14px;
    font-family: 'Courier New', monospace;
    color: black;
}

.collapsedConstraint{
    background-color: white;
    max-height: 0px;
    transition:max-height 0.35s ease;
    overflow: hidden;
    position: relative;
}

.unsatisfiedConstraintsDivContent{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
}

.collapsableConstraintHeading{
    display: flex;
    flex-direction: row;
}

.constraintsSuccess{


    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-grow: 1;

width: 100%;
border: 1px solid var(--outline);
margin: auto;
margin-top: 20px;


}
