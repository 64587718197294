.limitationBanner {
    background-color: var(--primary);
    border: 2px solid var(--primary);
    border-radius: 5px;
    font-size: 14px; 
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    padding: 5px 10px;
    
}

.limitationBanner p {
    margin: auto;
    padding: auto;
}



.limitationBannerLink {
    background-color: var(--secondary);
    border: 2px solid var(--secondary);
    border-radius: 5px;
    font-size: 14px;
    padding: 5px;
    margin-left: 10px;
    height: 30px;
    width: 100px;
    text-decoration: none;
    color: black;
}

@media screen and (max-width: 600px) {
    .limitationBanner {
        margin: 20px auto;
    }
  
  }