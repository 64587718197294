
.documentsBlockDiv{
}

.documentLink{
    border: 1px solid var(--outline);
    margin: 5px;
    border-radius: 5px;
    text-decoration: none;
    color: black;
    padding: 5px;
}

.documentLinkPdf{
    background-color: var(--secondary)
}

.documentLinkXlsx{
    background-color: var(--primary)
}


.detailsTable{
    border-radius: 5px;
    margin: auto;
    margin-top: 30px;
}

.detailsTable td {
    padding: 10px;
 }


.leftAlignedColumn{
    text-align: left;
}

.rightAlignedColumn{
    text-align: right;
}

.centerAlignedColumn{

    display: flex;
    justify-content: center;
}

.yearDocuments{
    list-style-position: inside;
    margin: 0px;
}

.missingDocumentsDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.missingDocumentsDiv p{
    margin-bottom: 0px;
}
