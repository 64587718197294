.searchResultsDiv{
    /*display: flex;*/
    margin-left: 10%;
    margin-right: 10%;
}

.pagination{
    margin: 0;
    margin-top: 20px;
    padding: 0;
    display: flex;
    margin-left: auto;
    margin-right: auto; 
    justify-content: flex-end;

}

.paginationNumbers {
  display: flex;
  flex-direction: row;
  list-style: none;
  margin: 10px
}
  
.pagination li {
    color: black;
    padding: 8px 16px;
    text-decoration: none;
    border: 1px solid var(--outline);
    cursor: pointer;
  }
  
.pagination li.selectedPage {
    background-color: var(--outline);
    color: white;
  }

/*.pagination li.unselectedPage {
    background-color: red;
    color: white;
}*/

.pagination li:hover:not(.selectedPage){
    background-color: var(--ternary);
}

.paginationLegend{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin:10px;
}

.paginationLegend p {
  margin: 2px
}

.paginationLimitation{
  font-size: 12px;
  color: var(--outline);
}


.searchTableList{
    width: 100%;
    margin: auto;
    margin-top: 30px;
  }
  
  .searchTableList table{
    width: 100%;
    border-collapse: collapse; 
    clear: none;
  
  }
  
  .searchTableList table tr {
    border-bottom: 1px solid var(--outline);
  }
  
  .searchTableList table tr td, th {
    padding: 10px;
  
  }
  
   .searchTableList table tbody tr:hover{
    box-shadow: 0px 0px 6px var(--outline);
  }

  @media screen and (max-width: 600px) {
    .searchTableList table td:nth-child(4) {
        display: none !important;
    }
    .searchTableList table th:nth-child(4) {
      display: none !important;
  }
    .searchTableList table td:nth-child(5) {
      display: none !important;
  }
    .searchTableList table th:nth-child(5) {
    display: none !important;
}
}

.companyCellName{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px;
}