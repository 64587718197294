:root{
  --primary: #c6f68d;
  --secondary: #c9a4f4;
  --ternary: aliceblue;
  --error: #ec7979;
  --success: green;
  --warning: #ffcc00;
  --background: #ffffff;
  --outline: grey;
  --not-verified: #ff6700;
  --verified: green;
}

html, #root, body, .App {
  height: 100%;
}

body{
  background-color: var(--background);
  font-family: 'Roboto';
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.blurred{
  filter: blur(8px);
-webkit-filter: blur(8px);
}

.clickableTableRow{
  cursor: pointer;
}


.detailsTable{
    margin: auto;
    margin-top: 30px;
    border: 1px solid var(--outline);
    border-radius: 5px;
    border-collapse: collapse;
}

.detailsTable td {
    padding: 10px;
    border: 1px solid var(--outline)
 }

 .detailsTable th{
  padding: 10px;
  border: 1px solid var(--outline)
 }

 .headingCell{
     font-weight: bold;
 }

 .detailsTable th{
   font-weight: bold;
 }

.leftAlignedColumn{
    text-align: left;
}

.rightAlignedColumn{
    text-align: right;
}

.centerAlignedColumn{

    display: flex;
    justify-content: center;
}

.collapsableDiv{
    width: 100%;
    border: 1px solid var(--outline);
    border-radius: 5px;
    cursor: pointer;
    overflow: hidden;
    margin: auto;
    margin-top: 20px;
}

.collapseArrow{
  margin-top: auto;
  margin-bottom: auto;
  float: left;
}

select {
  -webkit-appearance: none;
  appearance: none;
}

select:hover{
  cursor: pointer;
}

.Form input{
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
}


.FormInput {
  width: 100%;
  height: 22px;
  padding: 16px 12px;
  border: 2px solid var(--outline);
  margin-top: 5px;
}

.FormInput:focus{
  border: 2px solid var(--secondary);
}

.FormSubmit{
  background-color: var(--secondary);
  border: 2px solid var(--secondary);
  padding: 10px;
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
}

.ErrorMessage{
  font-size: 12px;
  padding: 10px;
  background-color: var(--error);
  border: 2px solid var(--error);
  border-radius: 5px;
  width: 100%;
}

.buttonLoaderWrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
}


/* Loader */
.loader {
  border: 4px solid var(--ternary);
  border-top: 4px solid var(--outline); 
  border-radius: 50%;
  animation: spin 2s linear infinite;
  width: 1em;
  height: 1em;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.termsDiv{
  font-size: 14px;
  color: var(--outline);
  display: flex
}

.termsDiv a {
  color: var(--outline);
}

.formErrorMessage{
  font-size: 12px;
  color: var(--error);
  text-align: left;
  padding-left: 5px;
  margin-top: 5px;
}

.mainContent{
  flex-grow: 1;
  align-self: stretch;
}

.blur {
  color: transparent;
  text-shadow: 0 0 10px black;
}

.verified {
  color: var(--verified);
}

.not-verified {
  color: var(--not-verified);
}

.myProgressSpinner{
  margin: 20px;
}

.mainAndAdsContent{
  display: flex;
  flex-direction: row;
}