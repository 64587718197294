
.downloadOriginal{
    cursor: pointer;
}

.downloadOriginal{
    text-decoration: none;
}

.urlBreak{
    word-break: break-all;
}