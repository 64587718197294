
.collapsedDiv{
    background-color: white;
    width: 100%;
    max-height: 0px;
    transition:max-height 0.35s ease;
    overflow: hidden;
    position: relative;
}

.uncollapsedDiv{
    border-collapse: collapse;
    background-color: white;
    width: 100%;
    transition:max-height 0.35s ease;
    border-top: 1px solid var(--outline);
    overflow: hidden;
    position: relative;
    cursor: default;
    padding: 10px;
    overflow: auto;
}

.collapsableDivHeading{
    display: flex;
    flex-direction: row;
}

.collapsableDivText{
    flex-grow: 1;
}

.dataTable{
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    border-collapse: collapse;
}
