.documentsStatsBlock{
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--outline);
    border-radius: 3px;
    padding: 10px;
}

.documentsStatsTable{
    max-width: 800px;
}

.noDocumentsDiv{
    font-size: 14px;
    color: #ff6700;;
    display: flex;
    margin-left: auto;
    margin-right: auto;
}