.multiCheckbox{
    display: flex;
    flex-direction: column;
    padding: 10px;
    background-color: var(--ternary);
    border-radius: 20px;
    border: 1px solid var(--outline);
    margin-top: 10px;
    margin-left: 5px;
    cursor: pointer;
    position: relative;
    display: inline-block;
}

.multiCheckboxFilled{
  background-color: var(--secondary);
}

.multiCheckboxUnfilled{
  background-color: var(--ternary);
}

.multiCheckbox:hover{
    background-color: var(--secondary);
}

.checkboxDiv{
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: var(--secondary);
    border-radius: 20px;
    border: 1px solid var(--outline);
    top:40px;
    padding: 10px;
    max-height: 600px;
    min-width: 200px;
    overflow: auto;
    z-index: 1;
}

.widerCheckBox{
  min-width: 400px;
}

.filteringMenu{
    display: flex;
    flex-direction: row;
    margin-left: 10%;
    margin-right: 10%;
    flex-wrap: wrap;
}

.filterButton{
    font-size: 14px;
    background-color: var(--ternary);
    height: 30px;
    width: 100px;
}

.filterButton:hover{
  background-color: var(--secondary);
}

.checkboxLabel{
display: flex;
align-items: row;
margin-left: 5px;
margin-top: 5px;
text-align: left;
}

.checkboxLabel span{
    padding-left: 10px;
}

.checkboxLabel input[type=checkbox] {
    transform: scale(1.5);
}

.checkboxLabel input[type=radio] {
    transform: scale(1.5);
}

.selectionHeader{
    display: flex;
    flex-direction: row;
}

.selectionText{
  display: flex;
  flex-direction: column;
}

.yearRange{
  background-color: var(--secondary);
}

.selectionHeader svg {
    margin-left: 5px;
}

/* Filtering table */

.searchResultsDiv{
    /*display: flex;*/
    margin-left: 10%;
    margin-right: 10%;
}

.pagination{
    margin: 0;
    margin-top: 20px;
    padding: 0;
    display: flex;
    margin-left: auto;
    margin-right: auto; 
    justify-content: flex-end;

}

.paginationNumbers {
  display: flex;
  flex-direction: row;
  list-style: none;
  margin: 10px
}
  
.pagination li {
    color: black;
    padding: 8px 16px;
    text-decoration: none;
    border: 1px solid var(--outline);
    cursor: pointer;
  }
  
.pagination li.selectedPage {
    background-color: var(--outline);
    color: white;
  }

/*.pagination li.unselectedPage {
    background-color: red;
    color: white;
}*/

.pagination li:hover:not(.selectedPage){
    background-color: var(--ternary);
}

.paginationLegend{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin:10px;
}

.paginationLegend p {
  margin: 2px
}

.paginationLimitation{
  font-size: 12px;
  color: var(--outline);
}


.filterTableList{
    width: 100%;
    margin: auto;
    margin-top: 30px;
  }
  
  .filterTableList table{
    width: 100%;
    border-collapse: collapse; 
    clear: none;
  
  }
  
  .filterTableList table tr {
    border-bottom: 1px solid var(--outline);
  }
  
  .filterTableList table tr td, th {
    padding: 10px;
  
  }
  
   .filterTableList table tbody:hover{
    box-shadow: 0px 0px 6px var(--outline);
  }

  @media screen and (max-width: 600px) {
    .filterTableList table td:nth-child(4) {
        display: none !important;
    }
    .filterTableList table th:nth-child(4) {
      display: none !important;
  }
    .filterTableList table td:nth-child(5) {
      display: none !important;
  }
    .filterTableList table th:nth-child(5) {
    display: none !important;
}
}

.otherRangeSelect{
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  align-items: center;
}

.otherRangeSelect label{
  margin: 10px;
}

.otherRangeSelect input{
  height: 30px;
  border: 1px solid var(--outline);
  border-radius: 5px;
}

.applyButton{
  position: absolute;
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
  bottom: 10%;  
  left: 50%; 
  transform: translate(-50%, -50%); 
  padding: 10px;
  box-shadow: 0 0 0 0 var(--secondary);
  padding: 10px;
  background-color: var(--secondary);
  -webkit-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  font-size: 14px;
  transition: all 300ms ease-in-out;
  cursor: progress;
  z-index: 1;
}

.exportButton{
  background-color: var(--primary);
  height: auto !important;
  width: auto !important;
  font-weight: bold;
}

.filteringInput {
  border: 1px solid var(--outline);
  border-radius: 5px;
  font-size: 18px;
  margin: 4px;
  padding: 8px;
}

.filteringGuide{
  font-weight: 500;
  border: 1px solid var(--outline);
  background-color: var(--ternary);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 30px;

}

.filteringGuideOl{
}

.emptyResults{
  display: flex;
  flex-direction: column;
  margin: 30px auto;
}

.selectedActivities{
  font-size: 0.8rem;
}

.selectedActivities p {
  margin: 0px;
}

.regionHeading{
  margin: 10px;
}

.filterDashboard{
  min-height: 800px;
}