

.tableList{
    width: 100%;
    margin: auto;
    margin-top: 15px;
    overflow: auto;
  }

  @media only screen and (max-width: 960px) {
    td, th {font-size: 12px;}
}
  
  .tableList table{
    width: 100%;
    border-collapse: collapse; 
    clear: none;
    margin-bottom: 15px;
  
  }
  
  .tableList table tr {
    border-bottom: 1px solid var(--outline);
  }
  
  .tableList table tr td, th {
    padding: 10px;
  
  }
  
   .tableList table tbody tr:hover{
    box-shadow: 0px 0px 6px var(--outline);
  }

.dataTable table{
    margin: 20px;
    margin-left: auto;
    margin-right: auto;
    border-collapse: collapse;
    border: 1px solid var(--outline);
    width: 100%;
    max-width: 100vw;
    overflow: scroll;
}

.dataTable td{
    border: 1px solid var(--outline);
    padding: 10px;
}

.detailsTable{
    border-radius: 5px;
    margin: auto;
    width: 90%;
    max-width: 100vw;
}

.documentDetailMain{
    margin: auto;
    max-width: 100vw;
}

.detailsTable td {
    padding: 10px;
 }

 .headingCell{
    font-weight: bold;
}

.rightAlignedColumn{
    text-align: right;
}

.centerAlignedColumn{

    display: flex;
    justify-content: center;
}

hr {
    margin-top: 20px;
    width: 80%;
}

.downloadButtons button{
    width: 200px;
    height: 50px;
    border-radius: 5px;
    font-size: 20px;
    margin: 10px;
    cursor: pointer;
}

.downloadButtons{
    margin: 20px;
}

.documentDetail{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-grow: 1;
}

.navigationBackDiv{
    margin: 10px;

  }

  .navigationBackDiv svg:hover{
    cursor: pointer;
    background-color: lightgray !important;
    border-radius: 10px;
    border: 1px solid lightgray !important;
  }

.verticalNavigationSpan{
    color: var(--outline);
    font-size: 14px;
    margin: 10px;
}

.noDataFoundDiv{
        background-color: white;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-grow: 1;

    width: 100%;
    border: 1px solid var(--outline);
    border-radius: 5px;
    margin: auto;
    margin-top: 20px;

}

.documentSkeleton{

    margin-left: auto;
    margin-right: auto;
    width: 80%;
    margin-top: 30px;
    height: 100%;

}

@media screen and (max-width: 1024px) {
    .documentDetail {
        flex-direction: column;
        align-items: center;
    }

    .navigationBackDiv{
        margin: 10px;
    }

    .verticalNavigation{
        display: inline;
        border: None;
    }
  }