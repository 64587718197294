
.headerDiv {
    width: 100%;
    background-color: var(--background);
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px var(--outline) solid;
}

.headlineDiv {
    margin-left: 20px;
    display: flex;
    align-items: center;
}

.leftHeaderPlaceholder{
    float:left;
}

.headlineDiv h1{
    padding: 2px 10px;
}

.headlineDiv a {
    text-decoration: None;
    color: black;
}

.rightHeaderPlaceholder {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.icon {
    color: var(--ternary);
    background-color: var(--outline);
    margin: 15px 2px;
    cursor: pointer;
    border: 0px solid var(--outline);
    border-radius: 50%;
}

.usernameCircleIcon {
    display:inline-block;
    width:1.1em;
    height:1.1em;
    text-align:center;
    vertical-align:middle;
    border: 3px solid var(--outline);
    border-radius: 50%;
    color: var(--outline);
    background-color: var(--ternary);
    margin: 15px 2px;
    font-size: 36px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.popUpMenu {
    border: 1px solid var(--outline);
    border-radius: 5px;
    position: absolute;
    top: 0px;
    right: 20px;
    margin-top: 60px;
}

.popUpMenu ul {
    list-style: None;
    padding: 0px;
    margin: 0px;
    margin-bottom: 10px;
    background-color: white;
}

.popUpMenu li {
    padding: 10px;
}

.popUpMenu li a{
    text-decoration: none;
    color: inherit;
}

.popUpMenu li:hover {
    background-color: var(--ternary);
}

.usernameText {
    font-size: 12px;
    padding: 10px;
    border-bottom: 1px solid var(--ternary);
    background-color: white;
    margin: 0px;
}

.loginButton{
    background-color: var(--secondary);
    height: 35px;
    width: 150px;
    border-radius: 5px;
    border: 2px solid var(--secondary);
    font-weight: bold;
    margin-bottom: 5px;
}

.functionsMenuItem{
    display: flex;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    text-decoration: None;
    margin-right: 10px;
    color: black;
    align-items: center;
    justify-content: center;
}

.functionsMenu{
    float: left;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.functionsMenuItem a {
    border: none !important;
    padding: none !important;
    color: black;
}

.functionsMenuItem > a.active{
    background-color: white !important;
    text-decoration: underline;
}

.loginOrRegister{
    display: flex;
    flex-direction: column;
}

.loginOrRegister a {
    color: var(--outline);
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 12px;
}

@media screen and (max-width: 1024px) {
    .loginOrRegister, .helpMenu {
        display: none !important;
    }
}

@media screen and (max-width: 600px) {
    .loginOrRegister, .helpMenu {
        display: none !important;
    }

    .headlineDiv img {
        display: none;
    }

    .headerDiv {
        flex-direction: column;
    }

    .headlineDiv h1 {
        margin-bottom: 0px;
        margin-top: 10px;
    }

    .rightHeaderPlaceholder{
        margin-left: 0px;
    }

}