.signUpForm{
    display: flex;
    flex-direction: column;
    margin: 20px auto;
    width: 400px;
}

.termsDiv input{
    width: 1em;
}

.termsCheckboxes{
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: left;
}

.termsDiv label{
    margin-left: 10px;
}