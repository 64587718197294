
.regionOutterBox{
    width: 50%;
}

.regionTile {
    border: 1px solid var(--outline);
    padding: 10px;
    margin: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-color: var(--background);
    flex-grow: 1;
    font-size: 18px;
    margin: 10px;
}

.regionTile p {
    margin: auto;
    padding: auto;
}

.regionTile:hover{
    background-color: var(--ternary);
}

.regionOutterBox a {
    color: black;
    font-weight: 400;
}