.financialDataDiv{
    margin: 20px;
}

.graphsMain{
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    min-height: 300px;
    overflow-x: scroll;
}

.collapsedGraph{
    background-color: white;
    width: 100%;
    max-height: 0px;
    transition:max-height 0.35s ease;
    overflow: hidden;
    position: relative;
}

.uncollapsedGraph{
    border-collapse: collapse;
    background-color: white;
    max-height:1000px;
    width: 100%;
    transition:max-height 0.35s ease;
    border-top: 1px solid var(--outline);
    overflow: hidden;
    position: relative;
    padding: 20px;
}

.collapseArrow{
    margin-top: auto;
    margin-bottom: auto;
    float: left;
}

.graphSelectInput{
    height: 50px;
    border: 1px solid var(--outline);
    padding-left: 20px;
    padding-right: 20px;
    background-color: var(--ternary);
    border-radius: 8px;
    width: 100%;
}

.styled-select {
    overflow: hidden;
    width: 100%;
    overflow: -moz-hidden-unscrollable;
    background: url(arrow_down.png) no-repeat left white;
    background-color: var(--ternary);
    border: 1px solid var(--outline);
    border-radius: 8px;
    display: inline-block;
    position: relative;
  }

  .styled-select select {
    background: transparent;
    -webkit-appearance: none;
    text-align: center;
    vertical-align: baseline;
    width: 100%;
    padding: 20px;
    font-size: 14px;
    border: 0;
  }

  .dot {
    height: 25px;
    width: 25px;
    background-color: var(--outline);
    border-radius: 50%;
    display: inline-block;
  }

  .descriptionDot{
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: inline-block;  
    margin: 3px;
  }

  .greyDot{
    background-color: var(--outline);
  }

  .dotVerified{
    background-color: var(--verified)
  }

  .dotNotVerified{
    background-color: var(--not-verified)
  }


  .domainHeadline{
      margin-bottom: 5px;
      margin-top: 5px;
  }

  .domainGraphDiv{
      display: flex;
      flex-direction: column;
      align-items: center;
  }

  .yearTh {
      display: flex;
      flex-direction: column;
  }

  .yearTh p {
      margin: 2px;
      align-items: center;
      justify-content: center;
  }

  .importantTableRow{
    font-weight: 700;
  }

 .expenseCategory{
  font-size: 1.5rem;
  margin-left: 5px;
 }