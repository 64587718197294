

.smallSidePannelBanner{
    width: 160px;
    height: 600px;
    align-items: center;
    justify-content: center;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
}

.bigSidePannelBanner{
    width: 300px;
    height: 600px;
    align-items: center;
    justify-content: center;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
}