
.similarCompanies{
    display: flex;
    flex-direction: row;
    max-width: 1600px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

.similarCompanies a {
    text-decoration: none;
}