.loading {
    display: inline-block;
    animation: rotation 1s infinite linear;
  }

  .disabledTableRow{
    cursor: not-allowed;
  }
  
  @keyframes rotation {
    to {
      transform: rotate(360deg);
    }
  }

  .tableList{
    margin: auto;
  }

  .copyIcon{
    cursor: pointer;
    width: fit-content;
    height: fit-content;
  }

  .copyIconTd{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .copyIcon:hover{
    box-shadow: 0px 0px 6px var(--secondary);
  }

  .copyIcon:active{
    box-shadow: 0px 0px 6px var(--primary);
  }