.deletedCompany{
    border: 1px solid var(--outline);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    height: 35px;
    width: 35px;
}

.deletedCompanyDashboard{
    height: 25px;
    width: 25px;
}


.deletedCompany svg{
    padding: 0px;
    margin: 5px;
    color: var(--error);
}
