
.cookiesBanner {
    border: 1px solid var(--outline);
    border-radius: 5px;
    background-color: var(--background);
    width: 400px;
    max-width: 100%;
    margin: 10px auto;
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    font-size: 14px;
    padding: 5px;
    z-index: 5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.cookiesButtons{
    display: flex;
    flex-direction: row;
}

.cookiesButtons button {
    font-size: 1.2rem;
    margin: 5px;
}

.cookiesAccept {
    border: 1px solid var(--outline);
    border-radius: 5px;
    background-color: var(--primary);
}

.cookiesReject {
    border: 1px solid var(--outline);
    background-color: var(--background);
    border-radius: 5px;
}