
.companyContactInfo{
    display: flex;
    flex-direction: column;
    margin: 30px;
    margin-left: auto;
    margin-right: auto;
}

.companyInfoRecord{
    margin: 5px;
}