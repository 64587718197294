
.documentUploadForm{
    /*display: flex;*/
    margin: auto;
    justify-content: center;
    margin: 10px;
}

.documentUploadDiv{
    padding: 15px;
    border: 3px dashed var(--outline);
    width: 600px;
    border-radius: 5px;
    /*display: flex;*/
    justify-content: center;
    margin: auto;
}

.documentUploadDiv:hover{
    background-color: var(--secondary);
    border: 3px solid var(--secondary);
    border-radius: 8px;
    transition: background-color ease-in-out 280ms;
    cursor: pointer;
    justify-content: center;
}


.button{
    width: 200px;
    height: 50px;
    background-color: var(--primary);
    border-radius: 8px;
    border: 1px solid var(--primary);
}

.disabledButton{
    cursor: not-allowed;
}

.documentUploadMain{
    width: 75%;
    margin: auto;
}

.uploadWarningBanner{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--outline);
    border-radius: 5px;
    padding: 10px;
}

.uploadButtonDiv{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

