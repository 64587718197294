.orLink{
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 0.8rem;
}

.orLinkIcon{
    margin: 0px 10px;
    font-size: 1rem !important;
}