
.metricsBlock{
    display: flex;
    flex-direction: column;
    border: 1px solid var(--outline);
    border-radius: 3px;
    margin: 10px;
    padding: 10px;
    position: relative;
}

.metricsHorizontal{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.blurred{
    filter: blur(8px);
  -webkit-filter: blur(8px);
}

@media screen and (max-width: 1024px) {
    .metricsHorizontal {
        flex-direction: column;
        align-items: center;
    }
  }