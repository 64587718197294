
.metricCircle{
    height: 150px;
    width: 150px;
    background-color: var(--ternary);
    border-radius: 50%;
    display: flex;
    border: 1px solid var(--outline);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px;
}

.metricValue{
    font-weight: bold;
    font-size: 22px;
    margin: 10px;
}

.metricName{
    font-size: 12px;
    font-weight: bold;
    color: var(--outline);
}
