
.blogContent{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 1.2rem;

}

.blogContainer{
    box-shadow: 0px 2px 5px 0px;
    width: 100%;
    padding: 50px 10px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.blogImage{
    width: 100%;
    max-width: 900px;
    padding: 10px;
    border: 2px solid var(--outline);
    border-radius: 9px;
    margin-bottom: 20px;
}

.blogText{
    margin: 20px;
    max-width: 1000px;
}

.blogLi{
    text-align: left;
}