.companyDownloadButton{
    position: fixed;
    bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;

    background-color: var(--primary);
    border: 1px solid var(--secondary);
    font-weight: bold;

    z-index: 1;
    
}

@media only screen and (max-width: 960px){
.companyDownloadButton {
    font-size: 14px;
    margin-left: 0px;
    margin-right: 0px;
}
}