.similarCompaniesTable{
    margin: auto;
    table-layout: fixed;
}

.lastDiv{
    margin-bottom: 30px;
}

.similarCompaniesTable th, td{
    padding: 3px;
    word-wrap:break-word;
}

@media screen and (max-width: 1024px) {
    .legalFormTd {
        display: none;
    }
  }


.linkButton{
    text-decoration: none !important;
    margin: 2px;
    border: 1px solid transparent;
    padding: 5px !important;
}

.linkButton > a {
    text-decoration: none !important;
}

.linkButton a:hover{
    background-color: var(--ternary);
    opacity: 0.5;
    border-radius: 5px;
    cursor: pointer;
}