.chartsDashboard{
    margin: 30px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.graphContainer{
    margin: 10px;
}

.graphHeading{
    margin: 20px;
}

.graphDataDescription{
    font-size: 16px;
    color: var(--outline);
    margin-bottom: 5px;
}