.footerDiv{
    margin-top: auto;
    flex-shrink: 0;
    width: 100%;
    background-color: aliceblue;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    box-shadow: 0px 3px 4px 3px var(--outline);
    font-size: 14px;
    margin-top: 5px;
    color: var(--outline);
}

.footerDiv p{
    margin: 10px;
}

.footerDiv a {
    padding: 5px;
    margin-left: 10px;

}

.footerBlock{
    display: flex;
    flex-direction: column;
}