.bigHorizontalBanner{
    display: flex;
    position: relative;
    width: 970px;
    height: 310px;
    margin: 20px auto;
    align-items: center;
    justify-content: center;
}

.smallHorizontalBanner{
    display: flex;
    position: relative;
    width: 300px;
    height: 250px;
    margin: 20px auto;
    align-items: center;
    justify-content: center;
}

.advert-label{
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: 5px;
    color: var(--outline);
    font-size: 0.7rem;
    z-index: 10;
}

.advert-image{
    z-index: 0;
}

.ad-banner-iframe{
    cursor: pointer;
}

.ad-banner-iframe img{
    cursor: pointer;
}