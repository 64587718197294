

.leadBoard{
    margin-left: auto;
    margin-right: auto;
  }

  .bigLeadBoardBanner{
    width: 728px;
    height: 90px;
    align-items: center;
    justify-content: center;
    position: relative;
}

.smallLeadBoardBanner{
    width: 320px;
    height: 100px;
    align-items: center;
    justify-content: center;
    position: relative;
}