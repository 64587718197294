
.domainHeaderDiv{
    margin-top: 30px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;
}