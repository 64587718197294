

.mobilePopUpAdSmall{
    height: 100px;
}

.mobilePopUpAdBig{
    height: 200px;
}

.mobilePopUpAd{
    max-height: 25vh;
    width: 100%;
    animation: slideInFromBottom 0.2s ease-in;
    margin-left: auto;
    margin-right: auto;
}

@keyframes slideInFromBottom {
    0% {
      transform: translateY(100%);
    }
  
    100% {
      transform: translateY(0);
    }
  }