
.loginForm{
    margin: auto;
    margin-top: 40px;
    width: 400px;
}

.signupBanner{
    margin: 10px;
    display: inline;
}

.successfullRegistrationBanner{
    background-color: var(--primary);
    border: 2px solid var(--primary);
    border-radius: 5px;
    font-size: 14px; 
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0px 10px;
    color: var(--outline);
    font-weight: bold;
    font-size: 14px;
    width: 400px;
}