
.tile {
    padding: 20px;
    border: 1px solid var(--outline);
    border-radius: 5px;
    margin: 20px;
    width: 300px;
    height: fit-content;
    position: relative;
}

.tile h1 {
    color: black;
}

.similarCompanyMetadata{
    color: var(--outline);
}

.registerText{
    position: absolute;
    margin: auto;
    margin-top: auto;
    top: 50%;
    left: 50%;
    font-weight: bold;
    font-size: 1.2em;
    color: black;

    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}