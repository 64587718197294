
.mobilePopUp{
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 100vw;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 40px 4px;
    z-index: 1000;
    background-color: var(--outline);
}

.mobilePopUpClose{
    margin-top: -30px;
    position: absolute;
    top: 0;
    right: 0
}