
body {
  margin: 0;
  font-family: 'Roboto', 'Arial', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*,*:focus,*:hover{
  outline: none;
}

* {
  box-sizing: border-box;
}