.searchGuideMain{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 20px;
}

.searchGuideOffer {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
    width: 80%;
}

.onlinePlatform{
    background-color: var(--secondary);
}

.oneReport{
    background-color: var(--primary);
}

.exportData{
    background-color: var(--ternary);
}

.searchGuideTile{
    font-size: 16px;
    padding: 30px;
    border: 1px solid var(--outline);
    border-radius: 5px;
    max-width: 800px;
    margin: 10px;
    justify-content: space-evenly;
    flex-grow: 1;

}

.regionsBlock {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;

}

@media screen and (max-width: 600px) {


    .searchGuideOffer {
        flex-direction: column;
    }

}