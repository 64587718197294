.similarCompaniesTable{
    margin: auto;
    table-layout: fixed;
}

.lastDiv{
    margin-bottom: 30px;
}

.similarCompaniesTable th, td{
    padding: 3px;
    word-wrap:break-word;
}

@media screen and (max-width: 1024px) {
    .legalFormTd {
        display: none;
    }
  }
