.companyDetailMain {
    display: flex;
    flex-grow: 1;
    height: 100%;
}

@media screen and (max-width: 1024px) {
    .companyDetailMain {
        flex-direction: column;
        align-items: center;
    }
  }

.companyDetail{
    flex-grow: 1;
}

.companyDetailRight {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    align-items: center;
}

.companyHeading {
    margin: 10px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
}

.companyHeading h1 {
    margin: 2px;
}

.companyNameDiv{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
}

.financialDataSkeleton{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
}

.documentsSkeleton{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
}

.tabsMenu {
    margin: 20px !important;

}

.tab-content{
    min-width: 80%;
}

.nav-link.active{
    background-color: var(--primary) !important;
}

.nav-link {
    border: 1px solid !important;
}

.loadingPlaceholder{
    margin: 20px;
}


